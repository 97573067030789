import React, { useState } from 'react';
import translation from '../../../../localization/Language';
import authService from '../../../../services/auth.service';
import Loader from '../../../shared/Loader/Loader';
import Logo from '../../../../assets/imgs/logo2.png';
import './ValidateOtp.scss';
import OtpInput from 'react-otp-input';
import ErrorAlert from './../../../shared/ErrorAlert/ErrorAlert';

const ValidateOtp = (props) => {
  const { setPagesState, pagesState } = props;

  const [submitState, setSubmitState] = useState(false);
  const [otpState, setOtpState] = useState('');
  const [errorState, setErrorState] = useState('');

  function handleChange(otp) {
    setOtpState(otp);
  }
  async function submit(e) {
    e.preventDefault();
    setSubmitState(true);
    try {
      await authService.validateOTP(localStorage.mobileNumber, otpState);
      localStorage.otp = otpState;

      setPagesState({ ...pagesState, changePassword: true, confirm: false });
      setSubmitState(false);
    } catch (ex) {
      setSubmitState(false);
      setErrorState(ex);
    }
  }
  async function resendOtp() {
    try {
      await authService.resendOtp(localStorage.mobileNumber);
    } catch (ex) {
      setErrorState(ex);
    }
  }
  return (
    <div>
      <div className="text-center mb-5">
        <img alt="logo" height="50" src={Logo} />
      </div>

      <div className="px-4">
        <h4 className="text-center f-bold">{translation.confirmOTP}</h4>
        <p className="text-center">
          {translation.pleaseEnterOTPSent} <br /> {localStorage.mobileNumber}
        </p>

        <form onSubmit={submit} dir="ltr">
          <div className="justify-content-center d-flex">
            <OtpInput
              className="otp-input justify-content-center"
              value={otpState}
              onChange={handleChange}
              numInputs={6}
              separator={<span>-</span>}
            />
          </div>

          <div className="text-center mt-5 mb-4">
            {translation.didntGetYourOTP}{' '}
            <a className="pretty-text-underline" href="#" onClick={resendOtp}>
              {translation.resendOtp}
            </a>
          </div>
          <div className="d-grid gap-2 mt-4 mb-3">
            <button
              tabIndex="1"
              disabled={!otpState}
              className={'btn btn-lg btn-primary btn-block '}
              type="submit"
            >
              {!submitState && translation.submit}
              {submitState && <Loader display="true" position="relative" color="white"></Loader>}
            </button>
          </div>
          {errorState && <ErrorAlert message={errorState}></ErrorAlert>}
        </form>
      </div>
    </div>
  );
};
export default ValidateOtp;
