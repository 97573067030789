import React, { useState } from 'react';
import LocationPicker from 'react-location-picker';

/* Default position */
const defaultPosition = {
  lat: 29.9878,
  lng: 31.925,
};

function GoogleLocationPicker(props) {
  const { setPosition, positionState } = props;
  function showPosition(position) {
    defaultPosition.lat =
      positionState.position && positionState.position.lat
        ? +positionState.position.lat || position.coords.latitude
        : position.coords.latitude;
    defaultPosition.lng =
      positionState.position && positionState.position.lng
        ? +positionState.position.lng || position.coords.longitude
        : position.coords.longitude;
    setPosition({ ...positionState, position: defaultPosition });
    loc.clearWatch(watch);
  }
  let loc = navigator.geolocation;
  let watch = loc.watchPosition(showPosition);
  function handleLocationChange({ position, address, places }) {
    // Set new location
    setPosition({ ...positionState, position: position });
  }

  return (
    <div>
      <h1>{positionState.address}</h1>
      <div>
        <LocationPicker
          containerElement={<div style={{ height: '100%' }} />}
          mapElement={<div style={{ height: '200px' }} />}
          defaultPosition={positionState.position}
          onChange={handleLocationChange}
        />
      </div>
    </div>
  );
}
export default GoogleLocationPicker;
