import http from './base.interceptor';

export default class Base {
  constructor(uri) {
    this.apiUrl = uri;
  }
  getPaged = async (data, apiName) => {
    const response = await http.post(this.apiUrl + apiName, data);
    return response.data;
  };
  get = async () => {
    const response = await http.get(this.apiUrl);
    return response.data;
  };
  getWithName = async (apiName) => {
    const response = await http.get(this.apiUrl + apiName);
    return response.data;
  };
  postWithName = async (apiName, data) => {
    const response = await http.post(this.apiUrl + apiName, data);
    return response.data;
  };
  getById = async (id) => {
    const response = await http.get(`${this.apiUrl}${id}`);
    return response.data;
  };
  add = async (data) => {
    const response = await http.post(this.apiUrl, data);
    return response.data;
  };
  edit = async (data) => {
    const response = await http.put(this.apiUrl, data);
    return response.data;
  };
  update = async (data) => {
    const response = await http.patch(this.apiUrl, data);
    return response.data;
  };
  remove = async (id) => {
    const response = await http.delete(`${this.apiUrl}${id}`);
    return response.data;
  };
}
