import React from 'react';
import { Spinner } from 'react-bootstrap';
import './loader.scss';
import ILoaderProps from './../../../models/ILoaderProps';

const Loader = ({ display = 'center', position = 'absolute', color = 'primary' }: ILoaderProps) => {
  let classes = '';
  classes = display;
  classes += ' ' + position;
  return (
    <div className={classes}>
      <Spinner animation="border" variant={color} />
    </div>
  );
};
export default Loader;
