import React, { useState } from 'react';
import translation from '../../../../localization/Language';
import { useForm } from 'react-hook-form';
import authService from '../../../../services/auth.service';
import Loader from '../../../shared/Loader/Loader';
import Logo from '../../../../assets/imgs/logo2.png';
import './Reset-Otp.scss';
import ErrorAlert from './../../../shared/ErrorAlert/ErrorAlert';

const ResetOtp = (props) => {
  const { setPagesState, pagesState } = props;
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
  } = useForm({ mode: 'all' });
  const [submitState, setSubmitState] = useState(false);
  const [errorState, setErrorState] = useState('');

  async function submit(e) {
    setSubmitState(true);
    try {
      await authService.sendOTP(getValues().mobileNumber);
      setSubmitState(false);
      localStorage.mobileNumber = getValues().mobileNumber;
      setPagesState({ ...pagesState, reset: false, confirm: true });
    } catch (ex) {
      setSubmitState(false);
      setErrorState(ex);
    }
  }

  return (
    <div className="fade-in">
      <div className="px-4">
        <div className="text-center">
          <img alt="logo" height="50" src={Logo} />
        </div>

        <div className="text-center my-5">
          <h3 className="heading-5 f-bold">{translation.confirmPhonenumber}</h3>
          <p className="text-center">{translation.weWillSendOTPToYourPhone}</p>
        </div>

        <form onSubmit={handleSubmit(submit)}>
          <div className="input-group mb-3 input-style-1">
            <span className="input-group-text" id="basic-addon1">
              <i className="fas fa-mobile-alt"></i>
            </span>
            <input
              tabIndex="0"
              id="mobileNumber"
              {...register('mobileNumber', {
                required: true,
                pattern: /^01[0-9]{9}/,
              })}
              type="text"
              className={'form-control ' + (errors.mobileNumber ? 'is-invalid' : '')}
              placeholder={translation.mobileNumber}
            />
            {errors.mobileNumber && (
              <p className="invalid-feedback">{translation.enterAValidMobileNumber}</p>
            )}
          </div>

          <div className="d-grid gap-2 mt-5 mt-4 mb-3">
            <button
              tabIndex="1"
              disabled={!isValid || submitState}
              className={'btn btn-primary '}
              type="submit"
            >
              {!submitState && translation.sendOtp}
              {submitState && <Loader display="true" position="relative" color="white"></Loader>}
            </button>
          </div>
          {errorState && <ErrorAlert message={errorState}></ErrorAlert>}
        </form>
      </div>
    </div>
  );
};
export default ResetOtp;
