import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
const FormsModal = (props) => {
  let { show, onClose } = props;
  return (
    <Modal show={show} onHide={onClose} centered dialogClassName="login-modal">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
    </Modal>
  );
};
export default FormsModal;
