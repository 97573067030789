import http from './base.interceptor';
import jwt_decode from 'jwt-decode';

function getBaseUrl() {
  return `api/auth/`;
}
function createGuid() {
  function S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
  return (
    S4() +
    S4() +
    '-' +
    S4() +
    '-4' +
    S4().substr(0, 3) +
    '-' +
    S4() +
    '-' +
    S4() +
    S4() +
    S4()
  ).toLowerCase();
}
function storeToken(token) {
  http.setJwt(token);
  localStorage.token = token;
  localStorage.removeItem('anonymous');
}
const register = async (registeredUser, tempId) => {
  const response = await http.post(getBaseUrl() + 'Register?tempId=' + tempId, registeredUser);
  const token = await response.data;
  storeToken(token);
};

const getLoggedUser = () => {
  let user;
  if (localStorage.token) {
    user = jwt_decode(localStorage.token);
  }
  return user || {};
};
const login = async (loginUser) => {
  const response = await http.post(getBaseUrl() + 'Login', loginUser);
  const token = await response.data;
  storeToken(token);
};

const sendOTP = async (mobileNumber) => {
  const response = await http.post(getBaseUrl() + `SendOtp?mobileNumber=${mobileNumber}`);
  return await response.data;
};
const resendOtp = async (mobileNumber) => {
  const response = await http.post(getBaseUrl() + `ResendOtp?mobileNumber=${mobileNumber}`);
  return await response.data;
};

const validateOTP = async (mobileNumber, otp) => {
  const response = await http.post(
    getBaseUrl() + `ValidateOTP?mobileNumber=${mobileNumber}&otp=${otp}`
  );
  return await response.data;
};

const forgetPassword = async (req) => {
  const response = await http.post(getBaseUrl() + 'ForgetPassword', req);
  return await response.data;
};

const logout = async () => {
  localStorage.removeItem('token');
  http.removeJwt();
  window.location.href = '/';
  const response = await http.get(getBaseUrl() + 'Logout');
  localStorage.anonymous = createGuid();
};
const getCustomerData = async () => {
  const response = await http.get(getBaseUrl() + 'GetCustomerData');
  return response.data;
};
let authObj = {
  register,
  getLoggedUser,
  login,
  logout,
  sendOTP,
  forgetPassword,
  validateOTP,
  resendOtp,
  createGuid,
  getCustomerData,
};
export default authObj;
