import React, { useState, useEffect, useContext } from 'react';
import FormsModal from '../../shared/Forms-Modal/FormsModal';
import Login from './../Login/Login';
import Register from '../Register/Register';
import ResetOtp from './../forget-password/Reset-Otp/ResetOtp';
import ValidateOtp from './../forget-password/Validate-Otp/ValidateOtp';
import ChangePassword from './../forget-password/Change-Password/ChangePassword';
import { ApplicationContext } from './../../../context/ApplicationContext';
//hello
const AuthWrapper = (props) => {
  const [appState, setAppState] = useContext(ApplicationContext);
  const [currentForm, setCurrentForm] = useState('');
  const [pagesState, setPagesState] = useState({
    login: true,
    register: false,
    reset: false,
    confirm: false,
    changePassword: false,
  });
  const onClose = () => {
    if (pagesState.login) setAppState({ ...appState, showAuth: false });
    else
      setPagesState({
        login: true,
        register: false,
        reset: false,
        confirm: false,
        changePassword: false,
      });
  };
  useEffect(() => {
    if (pagesState.login)
      setCurrentForm(<Login pagesState={pagesState} setPagesState={setPagesState}></Login>);
    else if (pagesState.register) setCurrentForm(<Register></Register>);
    else if (pagesState.reset)
      setCurrentForm(<ResetOtp setPagesState={setPagesState} pageState={pagesState}></ResetOtp>);
    else if (pagesState.confirm)
      setCurrentForm(
        <ValidateOtp setPagesState={setPagesState} pageState={pagesState}></ValidateOtp>
      );
    else if (pagesState.changePassword)
      setCurrentForm(
        <ChangePassword setPagesState={setPagesState} pageState={pagesState}></ChangePassword>
      );
  }, [pagesState]);

  return (
    <div>
      <FormsModal show={appState.showAuth} onClose={onClose}>
        {currentForm}
      </FormsModal>
    </div>
  );
};
export default AuthWrapper;
