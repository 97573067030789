import axios from 'axios';
import authService from '../services/auth.service';

axios.defaults.baseURL = window.BaseURL;
axios.defaults.headers.common['authorization'] = 'Bearer ' + localStorage.token;
axios.interceptors.request.use((config) => {
  config.headers['Accept-Language'] = localStorage.lang || 'ar';
  return config;
});
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status == 401) {
        authService.logout();
      }
    } else {
      // window.location.href = '/Error.html';
    }
    let message = error;
    if (error.response.data) {
      message =
        error.response.data.Message != null ? error.response.data.Message : error.response.data;
    }
    return Promise.reject(message);
  }
);
function setJwt(jwt) {
  axios.defaults.headers.common['authorization'] = 'Bearer ' + jwt;
}
function removeJwt() {
  axios.defaults.headers.common['authorization'] = null;
}
let actions = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch,
  setJwt,
  removeJwt,
};
export default actions;
