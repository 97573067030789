import Base from './base.service';

export class ItemBase extends Base {
  constructor() {
    super('api/items/');
  }
  getItems = (data) => this.getPaged(data, 'GetItems');
  getHotItems = (data) => this.getPaged(data, 'GetHotItems');
  getRecentlyViewedItems = (code) => this.getWithName(`GetRecentlyViewedItems?code=${code}`);
  getByCode = (code, customerId) =>
    this.getWithName(`GetByCode?code=${code}&customerId=${customerId}`);
}

export default ItemBase;
