import React from 'react';
import IRouter from './../models/IRouter';

const Home = React.lazy(() => import('../components/home/Home'));

const About = React.lazy(() => import('../components/about/About'));
const Terms = React.lazy(() => import('../components/TermsAndConditions/TermsAndConditions'));
const CheckOut = React.lazy(() => import('../components/check-out/CheckOut'));
const Branches = React.lazy(() => import('../components/branches/Branches'));
const Offers = React.lazy(() => import('../components/offers/Offers'));
const Orders = React.lazy(() => import('../components/orders/Orders'));
const OrdersDetails = React.lazy(() => import('../components/orders/order-details/OrderDetails'));
const ItemDetails = React.lazy(() => import('../components/item-details/ItemDetails'));
const Error = React.lazy(() => import('../components/Error/Error'));
const ContactUs = React.lazy(() => import('../components/contactUS/ContactUs'));
const CarService = React.lazy(() => import('../components/car-service/car-service'));
const Faq = React.lazy(() => import('../components/faq/Faq'));
const privacyPolicy = React.lazy(() => import('../components/privacy-policy/PrivacyPolicy'));

const routes: IRouter[] = [
  {
    path: '/',
    name: 'home',
    component: Home,
    protected: false,
  },
  {
    path: '/check-out',
    name: 'check-out',
    component: CheckOut,
    protected: false,
    hidden: true,
  },
  {
    path: '/orders',
    name: 'orders',
    component: Orders,
    protected: true,
  },

  {
    path: '/offers',
    name: 'offers',
    component: Offers,
    protected: false,
  },
  {
    path: '/branches',
    name: 'branches',
    component: Branches,
    protected: false,
  },

  {
    path: '/about',
    name: 'about',
    component: About,
    protected: false,
  },
  {
    path: '/contactus',
    name: 'contactUs',
    component: ContactUs,
    protected: false,
  },
  {
    path: '/carservice',
    name: 'carService',
    component: CarService,
    protected: false,
  },
  {
    path: '/faq',
    name: 'faq',
    component: Faq,
    protected: false,
  },
  {
    path: '/terms&conditions',
    name: 'terms',
    component: Terms,
    protected: false,
    hidden: true,
  },
  {
    path: '/privacy&policy',
    name: 'privacyPolicy',
    component: privacyPolicy,
    protected: false,
    hidden: true,
  },
  {
    path: '/order/:id',
    name: 'orderDetails',
    component: OrdersDetails,
    protected: false,
    hidden: true,
  },
  // {
  //   path: '/Item?id=:id',
  //   name: 'items',
  //   component: ItemDetails,
  //   protected: false,
  //   hidden: true,
  // },
  // {
  //   path: '*',
  //   name: 'Error',
  //   component: Error,
  //   protected: false,
  //   hidden: true,
  // },
];
export default routes;
