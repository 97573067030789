import React from 'react';
const ItemShimmer = (props) => {
  return (
    <div className="col">
      <div className="top">
        <div className="boxShimmer shine mb-1"></div>
        <div className="row">
          <div className="col-md-6">
            <span className="linesShimmer shine"></span>
          </div>
        </div>
      </div>
      <div className="bottom">
        <span className="linesShimmer shine"></span>
      </div>
    </div>
  );
};
export default ItemShimmer;
