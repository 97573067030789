import React from 'react';
import { Alert } from 'react-bootstrap';
const ErrorAlert = (props) => {
  let { message, isSuccess } = props;
  return (
    <Alert className="mt-2" variant={!isSuccess ? 'danger' : 'success'}>
      {message}
    </Alert>
  );
};
export default ErrorAlert;
