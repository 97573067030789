import React, { useState, useContext, Fragment, useEffect } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import IRouter from '../../../models/IRouter';
import translate from '../../../localization/Language';
import Logo from '../../../assets/imgs/menu_logo.png';
import ar from '../../../assets/imgs/icons8-egypt-96.png';
import en from '../../../assets/imgs/icons8-usa-96.png';
import './Navbar-Menu.scss';
import Container from 'react-bootstrap/Container';
import SideCart from '../../shared/Side-Cart/SideCart';
import { ApplicationContext } from './../../../context/ApplicationContext';
import translation from '../../../localization/Language';
import AuthService from '../../../services/auth.service';
export default function NavbarMenu({ routes }: any) {
  // const [open, setOpen] = useState(true);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [appState, setAppState] = useContext(ApplicationContext);
  const [expanded, setExpanded] = useState(false as any);
  useEffect(() => {
    let newCount = 0;
    let totalBill = 0;
    for (let item of appState.cart) {
      newCount += +item.ItemsCount;
      totalBill += +item.TotalPrice;
    }
    setAppState({
      ...appState,
      itemsCount: newCount,
      totalBill: totalBill.toFixed(),
      deliveryFees:
        appState.deliveryType != 3
          ? appState.feesObj.Fees
            ? appState.feesObj.Fees + appState.feesObj.FeesPerUnit * (newCount - 1)
            : 0
          : 0,
    });
  }, [appState.cart, appState.deliveryType, appState.feesObj]);

  function changeLang(lang: string) {
    setAppState({ ...appState, lang: lang });
    translation.setLanguage(lang);
    const root = document.querySelector('html');
    if (root) root.dir = appState.lang === 'ar' ? 'rtl' : 'ltr';
    window.location.href = window.location.href;
  }
  function setShowAuth() {
    setAppState({ ...appState, showAuth: true });
  }

  return (
    <Navbar
      fixed="top"
      className="navbar-component "
      bg="primary"
      collapseOnSelect
      expand="lg"
      variant="dark"
      expanded={expanded}
    >
      <Container className="position-relative">
        <Navbar.Brand href="/">
          <img alt="Kumho Shop" src={Logo} height="45"></img>
        </Navbar.Brand>

        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => setExpanded(expanded ? false : 'expanded')}
        />

        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Fragment>
              {routes.map((route: IRouter) => (
                <Fragment key={route.path}>
                  {!route.hidden &&
                    (!route.protected ||
                      (route.protected && (AuthService.getLoggedUser() as any).Id)) && (
                      <NavLink
                        id={route.path}
                        to={route.path}
                        exact
                        activeClassName="selected"
                        onClick={() => setExpanded(false)}
                      >
                        {translate[route.name]}
                      </NavLink>
                    )}
                </Fragment>
              ))}
              {/* <a href="/terms&conditions.html">{translate.terms}</a> */}
            </Fragment>
          </Nav>

          <Nav>
            <a href="tel:15506" className="h2 no-hover">
              <i className="fas fa-phone"></i>
              <span style={{ fontWeight: 'bold' }}>15506</span>
            </a>
            <div className="lang">
              {appState.lang !== 'ar' && (
                <a onClick={() => changeLang('ar')}>
                  العربية <img src={ar} alt="" />
                </a>
              )}
              {appState.lang !== 'en' && (
                <a onClick={() => changeLang('en')}>
                  English <img src={en} alt="" />
                </a>
              )}
            </div>
          </Nav>
        </Navbar.Collapse>

        <div className="top-side-navbar">
          <Nav>
            {appState.user.username ? (
              <Fragment>
                <NavDropdown
                  className="user-menu"
                  title={appState.user.username}
                  id="collasible-nav-dropdown"
                >
                  <NavDropdown.Item href="" onClick={() => AuthService.logout()}>
                    {translation.logout} <i className="fas fa-sign-out-alt"></i>
                  </NavDropdown.Item>
                </NavDropdown>
                {/* <Nav.Link className="notification">
                  <i className="fas fa-bell"></i>
                  <span className="num">2</span>
                </Nav.Link> */}
              </Fragment>
            ) : (
              <NavLink className="username" to="#" onClick={setShowAuth}>
                <span>{translation.login}</span>
              </NavLink>
            )}
            <Nav.Link className="cart" onClick={handleShow} disabled={appState.itemsCount == 0}>
              <span className="name">{translation.cart}</span>
              <span className="inner ms-2">
                <i className="fas fa-shopping-cart"></i>
                <span className="num">{appState.itemsCount}</span>
              </span>
            </Nav.Link>
          </Nav>
        </div>
      </Container>
      <SideCart show={show} handleClose={handleClose}></SideCart>
    </Navbar>
  );
}
