import React, { Fragment, useEffect, useState } from 'react';
import './Footer.scss';
import translation from '../../../localization/Language';
import Offers from './../../offers/Offers';
import ContactService from '../../../services/contact.service';
import BranchService from '../../../services/branch.service';
import { NavLink, Link, Switch, Route } from 'react-router-dom';
import IRouter from '../../../models/IRouter';
import routes from './../../../routes/app-router';
export default function Footer() {
  const [contactState, setContactState] = useState([]);
  let [branches, setBranches] = useState([]);

  useEffect(() => {
    async function getContacts() {
      let data = await new ContactService().get();
      setContactState(data);
      let branchesData = await new BranchService().get();
      setBranches(branchesData);
    }
    getContacts();
  }, []);
  return (
    <footer className="footer">
      <div className="container bottom_border">
        <div className="row">
          <div className=" col-sm-6 col-md   col-12 col">
            <h5 className="headin5_amrc col_white_amrc pt2">{translation.contactUs}</h5>
            <p className="mb10">
             {translation.contactUsText}
            </p>
            {contactState.map((row: any) => (
              <Fragment key={row.Phone}>
                <p>
                  <a href={'tel:' + row.Phone} className="text-white">
                    <i className="fa fa-phone"></i> {row.Phone}
                  </a>
                </p>
              </Fragment>
            ))}
             <p className="mb10">
             {translation.orEmailus}
            </p>
            {contactState.map((row: any) => (
              <Fragment key={row.Email}>
                <p>
                  <i className="fa fa-envelope"></i> {row.Email}
                </p>
              </Fragment>
            ))}
          </div>

          <div className=" col-sm-6 col-md  col-12 col">
            <h5 className="headin5_amrc col_white_amrc pt2">{translation.ourBranches}</h5>

            <ul className="footer_ul2_amrc">
              {branches.map((branch: any) => (
                <li key={branch.Id}>
                  <NavLink exact to="/branches" className="text-white">
                    {branch.Name}
                  </NavLink>
                  <p>{branch.Address}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className="container">
        <ul className="foote_bottom_ul_amrc">
          <li>
            <NavLink to="/">{translation.home}</NavLink>
          </li>
          <li>
            <NavLink exact to="/orders">
              {translation.orders}
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/offers">
              {translation.offers}
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/branches">
              {translation.branches}
            </NavLink>
          </li>

          <li>
            <NavLink exact to="/about">
              {translation.about}
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/carservice">
              {translation.carservice}
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/terms&conditions">
              {translation.terms}
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/privacy&policy">
              {translation.privacy}
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/faq">
              {translation.faq}
            </NavLink>
          </li>
        </ul>

        <p className="text-center">
          Copyright @2021 | Designed By{' '}
          <a className="text-white" href="http://www.MiracleEgypt.com">
            Miracle Egypt
          </a>
        </p>

        <ul className="social_footer_ul">
          <li>
            <a href="https://www.facebook.com/KumhoEG/about/?ref=page_internal">
              <i className="fab fa-facebook-f"></i>
            </a>
          </li>
          <li>
            <a href="https://twitter.com/kumhotyre?lang=ar">
              <i className="fab fa-twitter"></i>
            </a>
          </li>

          <li>
            <a href="https://www.instagram.com/kumhotireegypt/?fbclid=IwAR1gDyHxBOBrpVRRiTnkdzo2VRup_h-9opJWuH--Pp7sJj1gK6rnBx0LHik">
              <i className="fab fa-instagram"></i>
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
}
