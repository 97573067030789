import React, { Fragment, Suspense, useEffect, useState } from 'react';
import IRouter from '../../models/IRouter';
import NavbarMenu from '../common/Navbar-Menu/Navbar-Menu';
import {
  Route,
  Redirect,
  useLocation,
  BrowserRouter as Router,
  Link,
  Switch,
} from 'react-router-dom';
import { AnimatedSwitch } from 'react-router-transition';
import Loader from './../shared/Loader/Loader';
import routes from './../../routes/app-router';
import AuthWrapper from '../auth/Auth-Wrapper/AuthWrapper';
import Footer from '../common/Footer/Footer';
import ItemDetails from './../item-details/ItemDetails';
import Error from '../Error/Error';

export default function Layout() {
  //const location = useLocation();
  return (
    <Fragment>
      <Router>
        <Suspense
          fallback={
            <div style={{ height: '600px' }}>
              <Loader display={'absolute'} position={'center'} color="primary" />
            </div>
          }
        >
          {/* location.pathname.toLowerCase() !== '/login' &&  */}
          {<NavbarMenu routes={routes}></NavbarMenu>}

          <Fragment>
            {/* <AnimatedSwitch
              atEnter={{ opacity: 0 }}
              atLeave={{ opacity: 0 }}
              atActive={{ opacity: 1 }}
              className="switch-wrapper"
            > */}
            <Switch>
              {routes.map((route: IRouter) => (
                <Route exact path={route.path} component={route.component} key={route.path}></Route>
              ))}
              <Route
                to={{
                  pathname: '/item',
                  search: '?id=react',
                }}
                component={ItemDetails}
              ></Route>
            </Switch>
            {/* </AnimatedSwitch> */}
            <div style={{ position: 'relative' }}>
              <Footer></Footer>
            </div>
          </Fragment>
        </Suspense>
      </Router>

      <AuthWrapper></AuthWrapper>
    </Fragment>
  );
}
