import React, { Fragment, useContext } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import NumericInput from 'react-numeric-input';
import { ApplicationContext } from '../../../context/ApplicationContext';
import cartService from '../../../services/cart.service';
import authService from '../../../services/auth.service';
import translation from './../../../localization/Language';

import { useHistory } from 'react-router-dom';
const SideCart = (props) => {
  let history = useHistory();
  const [appState, setAppState] = useContext(ApplicationContext);
  let { show, handleClose } = props;

  async function remove(id) {
    setAppState({ ...appState, cart: [...appState.cart.filter((c) => c.CartId != id)] });
    await new cartService().remove(id);
  }
  async function updateCart(item) {
    let res = await new cartService().edit(item);
  }
  function onChange(e, item) {
    let index = appState.cart.findIndex((c) => c.Id == item.Id);
    if (index !== -1) {
      appState.cart[index].ItemsCount = e;
      appState.cart[index].TotalPrice = Math.round(e * item.NewPrice);
      setAppState({
        ...appState,
        cart: [...appState.cart],
      });
      updateCart({
        itemsCount: e,
        customerId: localStorage.anonymous || authService.getLoggedUser().Id,
        newPrice: appState.cart[index].TotalPrice,
        id: appState.cart[index].CartId,
      });
    }
  }
  return (
    <Offcanvas placement={'start'} show={show} onHide={handleClose}>
      <Offcanvas.Header closeButton></Offcanvas.Header>
      <Offcanvas.Body>
        <div className="inner">
          <div className="top">
            <div className="flex-3 f-bold">
              <span>{translation.cart}</span>
              <span>
                {appState.itemsCount} {translation.elements}
              </span>
            </div>

            <hr />

            {appState.cart.map((item) => (
              <Fragment key={item.Id}>
                <div className="item row">
                  <div className="col-9 flex-6">
                    <img
                      width="100"
                      src={'/appImages/' + item.Image}
                      alt=""
                      className="img-thumbnail"
                    />
                    <div className="middle">
                      <h4 className="heading">{item.Name}</h4>
                      <p className="mb-2">{item.MainGroupName}</p>
                      <p className="mb-2">
                        {translation.size} :{item.Id}
                      </p>
                      <div className="flex-1">
                        <p className="mb-0 me-3">{translation.qty}</p>
                        <span>
                          <NumericInput
                            className="form-control"
                            value={item.ItemsCount}
                            mobile
                            onChange={(e) => onChange(e, item)}
                            min={1}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 end">
                    <p className="lang-fix-1">
                      {item.TotalPrice} {translation.egp}
                    </p>
                    <p className="text-danger mb-0 pointer" onClick={() => remove(item.CartId)}>
                      {translation.delete} <i className="fas fa-trash-alt ms-2"></i>
                    </p>
                  </div>
                </div>

                <hr className="my-4" />
              </Fragment>
            ))}
            <div className="info f-bold">
              <div className="flex-1 mb-3">
                <span className="text text-success">{translation.totalValue} :</span>
                <span className="lang-fix-1">
                  {appState.totalBill} {translation.egp}
                </span>
              </div>
              <div className="flex-1 mb-3">
                <span className="text">{translation.deliveryCost} :</span>
                <span className="lang-fix-1">
                  {appState.deliveryFees} {translation.egp}
                </span>
              </div>
            </div>
          </div>
          <footer>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                history.push('/check-out');
                handleClose();
              }}
            >
              {translation.confirmOrder} {+appState.totalBill + appState.deliveryFees}
              {translation.egp}
            </button>
            <a href="#" onClick={handleClose} className="mb-0 text pretty-text-underline">
              {translation.continueShopping} <i className="fas fa-arrow-left"></i>
            </a>
          </footer>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};
export default SideCart;
