import React, { useState } from 'react';
import translation from '../../../../localization/Language';
import { useForm } from 'react-hook-form';
import authService from '../../../../services/auth.service';
import Loader from '../../../shared/Loader/Loader';
import Logo from '../../../../assets/imgs/logo2.png';
import './ChangePassword.scss';
import ErrorAlert from './../../../shared/ErrorAlert/ErrorAlert';

const ChangePassword = (props) => {
  const { setPagesState, pagesState } = props;
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
  } = useForm({ mode: 'all' });
  const [submitState, setSubmitState] = useState(false);
  const [errorState, setErrorState] = useState('');

  async function submit(e) {
    setSubmitState(true);
    try {
      await authService.forgetPassword({
        ...getValues(),
        otp: localStorage.otp,
        mobileNumber: localStorage.mobileNumber,
      });
      localStorage.removeItem('mobileNumber');
      localStorage.removeItem('otp');
      setSubmitState(false);
      setPagesState({ ...pagesState, changePassword: false, login: true });
    } catch (ex) {
      setSubmitState(false);
      setErrorState(ex);
    }
  }

  return (
    <div className="fade-in">
      <div className="px-4">
        <div className="text-center">
          <img alt="logo" height="50" src={Logo} />
        </div>

        <hr className="dashed" />

        <p className="text-center">{translation.changePassword}</p>

        <form onSubmit={handleSubmit(submit)}>
          <div className="input-group mb-3 input-style-1">
            <span className="input-group-text" id="basic-addon1">
              <i className="fas fa-lock"></i>
            </span>
            <input
              tabIndex="0"
              id="newPassword"
              {...register('newPassword', { required: true })}
              type="password"
              className={'form-control ' + (errors.newPassword ? 'is-invalid' : '')}
              placeholder={translation.password}
            />
            {errors.password && <p className="invalid-feedback">{translation.requiredField}</p>}
          </div>

          <div className="input-group mb-3 input-style-1">
            <span className="input-group-text" id="basic-addon1">
              <i className="fas fa-lock"></i>
            </span>
            <input
              tabIndex="1"
              id="confirmPassword"
              {...register('confirmPassword', {
                validate: (value) => value === getValues().newPassword,
              })}
              type="password"
              className={'form-control' + (errors.confirmPassword ? 'is-invalid' : '')}
              placeholder={translation.confirmPassword}
            />
            {errors.confirmPassword && <p className="invalid-feedback">{translation.passwordMismatch}</p>}
          </div>

          <div className="d-grid gap-2 mt-4 mb-3">
            <button
              tabIndex="2"
              disabled={!isValid || submitState}
              className={'btn btn-lg btn-primary btn-block '}
              type="submit"
            >
              {!submitState && translation.submit}
              {submitState && <Loader display="true" position="relative" color="white"></Loader>}
            </button>
          </div>
          {errorState && <ErrorAlert message={errorState}></ErrorAlert>}
        </form>
      </div>
    </div>
  );
};
export default ChangePassword;
