import React, { useState, Fragment, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ApplicationContext } from '../../context/ApplicationContext';
import translation from '../../localization/Language';
import Layout from '../layout/Layout';
import ReactNotification from 'react-notifications-component';
import authService from '../../services/auth.service';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import cartService from '../../services/cart.service';
import AreaService from '../../services/area.service';
import { hubConnection } from 'signalr-no-jquery';

library.add(fab, fas);

const sharedStatus = {
  lang: localStorage.lang || 'ar',
  user: authService.getLoggedUser(),
  cart: [],
  itemsCount: 0,
  totalBill: 0,
  showAuth: false,
  feesObj: {},
  deliveryFees: 0,
  deliveryType: 1,
  hub: {},
};

function App() {
  const [appState, setAppState] = useState(sharedStatus);
  const [hubState, setHubState] = useState(sharedStatus);
  translation.setLanguage(appState.lang);
  localStorage.lang = appState.lang;
  const root = document.querySelector('html');
  if (root) root.dir = appState.lang === 'ar' ? 'rtl' : 'ltr';
  const connectHub = () => {
    const signalrConnection = hubConnection(`${window.BaseURL}`);
    const hub = signalrConnection.createHubProxy('miracleshop');
    signalrConnection.start().done(() => {
      console.log('connected');
      setHubState(hub);
    });
  };
  useEffect(() => {
    setAppState({ ...appState, hub: hubState });
  }, [hubState]);
  useEffect(() => {
    if (appState.user.Id) connectHub();
  }, []);
  useEffect(() => {
    async function initCart() {
      if (!localStorage.token && !localStorage.anonymous) {
        localStorage.anonymous = authService.createGuid();
      } else {
        let response = await new cartService().getById(
          localStorage.anonymous || authService.getLoggedUser().Id
        );

        let response2 = {};
        if (authService.getLoggedUser().Id) {
          response2 = await new AreaService().getfees();
        }
        setAppState({
          ...appState,
          feesObj: response2 || {},
          cart: response.map((item) => ({
            ...item,
            Name: item.ItemName,
            Id: item.ItemId,
            CartId: item.Id,
          })),
        });
      }
    }
    initCart();
  }, []);
  // useEffect(() => {
  //   async function getFees() {
  //     let response = await new AreaService().getfees();
  //     console.log(response);
  //     setAppState({
  //       ...appState,
  //       feesObj: response || {},
  //     });
  //   }
  //   (authService.getLoggedUser() as any).Id && getFees();
  // }, []);
  return (
    <Fragment>
      <BrowserRouter>
        <ApplicationContext.Provider value={[appState, setAppState]}>
          <ReactNotification />
          <Layout />
        </ApplicationContext.Provider>
      </BrowserRouter>
    </Fragment>
  );
}

export default App;
