import LocalizationString from 'react-localization';

let translation = new LocalizationString<any>({
  en: {
    login: 'Login',
    home: 'Home',
    orders: 'Orders',
    success: 'Transaction Succeeded',
    no: 'Cancel',
    yes: 'Yes',
    confirm: 'Are you sure?',
    deleteMessage: 'Delete order number ',
    miracle: 'Miracle',
    loginText: 'Sign in to miracle accounting',
    userName: 'Username',
    password: 'Password',
    confirmPassword: 'Confirm Password',
    branches: 'Branches',
    folders: 'Folders',
    preview: 'Preview',
    logout: 'Sign Out',
    add: 'Add',
    edit: 'Edit',
    remove: 'Remove',
    error: 'Some thing went wrong',
    about: 'About Us',
    offers: 'Offers',
    mobileNumber: 'Mobile Number',
    name: 'Full Name',
    address: 'Address',
    register: 'Register',
    submit: 'Submit',
    sendOtp: 'Send OTP',
    resendOtp: 'Resend OTP',
    location: 'Map location',
    tire: 'Tire',
    batteries: 'Batteries',
    price: 'Price',
    size: 'Size',
    descending: 'Descending',
    ascending: 'Ascending',
    discount: 'Discount',
    share: 'Share',
    loginQ: 'Login?',
    noResult: 'No Search Result',
    confirmDelete: 'Are you sure, do you want to delete your order?',
    cart: 'Cart',
    search: 'Search',
    rimWidth: 'Rim Width',
    rimHeight: 'Rim Height',
    rimSize: 'Rim Size',
    brands: 'Brands',
    discounts: 'Discounts',
    addToCart: 'Add To Cart',
    purchase: 'Purchase',
    seeMore: 'See More',
    branch: 'Branch',
    getHelp: 'Get Help',
    email: 'Email',
    phones: 'Phones',
    cancelOrder: 'Cancel Order ?',
    date: 'Date',
    orderId: 'Order ID',
    waiting: 'Waiting',
    accepted: 'Accepted',
    charged: 'Charged',
    delivered: 'Delivered',
    searchByOrderId: 'Search By Order ID',
    previousOrders: 'Previous Orders',
    status: 'Status',
    amount: 'Amount',
    orderDate: 'Order Date',
    ourBranches: 'Our Branches',
    contactUs: 'Contact Us',
    pleaseEnterPhoneAndPass: 'Please enter your phone and password',
    createNewAccount: 'Create new account?',
    forgotYourPassword: 'Forgot your password?',
    or: 'or',
    requiredField: 'This field is required',
    passwordMismatch: 'Password Mismatch',
    enterValidName: 'Please enter a valid name',
    enterValidNumber: 'please enter a valid number',
    area: 'Area',
    phoneNumbers: 'Phone numbers',
    shoppingCart: 'Shopping cart',
    elements: 'Elements',
    qty: 'Quantity',
    total: 'Total',
    delete: 'Delete',
    egp: 'EGP',
    continueShopping: 'Continue shopping',
    summary: 'Summary',
    totalValue: 'Total value',
    confirmOrder: 'Confirm order',
    paymentMethod: 'Payment method',
    cashOnDelivery: 'Cash on delivery',
    visaOnDelivery: 'Visa on delivery',
    deliveryMethod: 'Delivery method',
    deliveryToHome: 'Delivery to home',
    deliveryFrom3to7Days: 'Delivery from 3 to 7 days',
    mountInBranch: 'Mount in branch',
    doYouReallyWantToConfirmYourOrder: 'Do you really want to confirm your order?',
    region: 'Region',
    delivery: 'Delivery',
    positionOnMap: 'Position on google maps',
    changePassword: 'Change password',
    confirmPhonenumber: 'Confirm phone number',
    weWillSendOTPToYourPhone: 'We will send OTP to your phone',
    enterAValidMobileNumber: 'Please enter a valid mobile number',
    confirmOTP: 'Confirm OTP',
    pleaseEnterOTPSent: 'Please enter OTP which sent to your number',
    didntGetYourOTP: 'Did not received your OTP?',
    recentlyViewed: 'Recently viewed',
    viewMore: 'View more',
    productDetails: 'Product details',
    details: 'Details',
    productionDate: 'Production date',
    buyProduct: 'Buy product',
    relatedToYourSearch: 'Products related to your search',
    trackYourOrder: 'Track your order',
    previousOrder: 'Previous order',
    enterOrderId: 'Enter order number',
    purchaseItem: 'Purchase Product',
    addItemToCart: 'Add to cart',
    deliveryCost: 'Delivery fees',
    back: 'Back',
    backToHome: 'Back To Main Page',
    errorNotFound: 'Error',
    notFound: 'Page Not Found',
    allItemsAreSelected: 'all ',
    value: 'Value',
    services: 'Services',
    workingHours: 'Working Hours',
    loginToCompleteOrder: 'Login To Complete Order',
    comingSoon: 'Coming Soon',
    terms: 'Terms&Conditions',
    privacy: 'Privacy&Policy',
    acceptTerms: 'I agree ',
    and: 'and',
    selectArea: 'Select Area',
    contactUsText:'If you have any request or complain, please call us on:  ',
    orEmailus:'or send email on :',
    carservice:'Car Services',
    carService:'Car Services',
    Faq:'FAQ',
    faq:'FAQ',

    Q1:'Are the tires offered under warranty ?',
    A1:'All tires available on our website are brand new, full value and subject to the manufacturer’s warranty.',

    Q2:'What are the accepted payment methods ?',
    A2:'You can pay cash or via credit cards.',

    Q3:'Do the prices include value added tax ? ',
    A3:'Yes, prices include all applicable taxes. You will receive a tax invoice.',

    Q4:'Are there any additional fees for installing tires ?',
    A4:'The prices listed in your order on our website are all you will pay without any hidden charges. You can trust that what you see is what you will pay. All tires installation operations, which include unwinding and installing the tire, bluffing, alignment, supplying the tire with air or nitrogen are free services provided to you within the branch. ',

    Q5:'What is the production date of the tires offered on the website ?',
    A5:'All tires we offer is new and no more than 24 months old (according to the recommendation of most tires expert and manufactures). We also commit to proper tires storage that ensures continuous tire rotation Despite our best efforts to ensure the latest production date, we offer a customer discount for tires older than 18 months.',

    Q6:'How can I submit a complaint?',
    A6:'If you are not satisfied with the level of services or any problems you have related to the service or the product, you must inform customer service, who will apply our complaint procedures and direct your complain to relevant department ',
    
    Q7:'Why buy from here ?',
    A7_1:'1- Variety: You will find all the international brands you are looking for, all in one place ',
    A7_2:'2- Get the best price without hidden costs: We offer you the best price for the tire without any hidden charges, you will receive a 100% tax invoice, there will be no hidden charges. Free unwinding, installation, bluffing and alignment ',
    A7_3:'3- Payment via credit cards: You can pay via credit cards.',
    A7_4:'4- Save your: time We know that your time is precious, and your schedule is very busy, no queues at our site, you are always number #1.Shopping for tires can be a tedious and time-consuming task. With us, it becomes easy. Shopping on our site make an easy purchase that you won’t even have to leave your home. All the information you need is available (price, engraving shape, specifications,) and if you are not sure about your tire size, you can always seek help through the telephone number 15506 – 01220055500 – 01200600362',
    A7_5:'5- After sales service: Through customer service center, you can seek advice at any time, seven days a week, and if tire check is necessary needed, you will be directed to the nearest services center to you to conduct the necessary checks on the tire, you just have to call15506 - 01220055500 – 01200600362',
    A7_6:'6- Mobile service: You can request the installation of tires through mobile services. Skilled technicians will come to you in the place you select, whether your home or workplace, through a car specially equipped to install tires.This service is only available inside Cairo.',
  },
  ar: {
    login: 'تسجيل الدخول',
    home: 'الرئيسية',
    customerService: 'خدمة العملاء',
    orders: 'تتبع طلباتك',
    success: 'عملية ناجحة',
    no: 'تراجع',
    yes: 'تاكيد',
    confirm: 'هل تريد التاكيد ؟',
    deleteMessage: 'الغاء الطلب رقم ',
    miracle: 'ميراكل',
    loginText: 'الدخول الى ميراكل المحاسبى ',
    userName: 'اسم المستخدم',
    password: 'كلمة السر',
    confirmPassword: 'تأكيد كلمة السر',
    branches: 'الفروع',
    folders: 'المجلدات',
    xlsFile: 'اكسيل',
    logout: 'خروج',
    add: 'اضافة',
    edit: 'تعديل',
    remove: 'الغاء',
    error: 'حدث خطا بالنظام',
    about: 'نبذة عنا',
    offers: 'عروض اليوم',
    mobileNumber: 'رقم الهاتف',
    name: 'الاسم',
    address: 'العنوان',
    register: 'انشاء حساب',
    submit: 'تاكيد',
    sendOtp: 'ارسال الرقم المتغير',
    resendOtp: 'اعادة ارسال الرقم المتغير ',
    location: 'الموقع على الخريطة',
    tire: 'إطارات',
    batteries: 'بطاريات',
    price: 'السعر',
    size: 'المقاس',
    descending: 'تنازلى',
    ascending: 'تصاعدى',
    discount: 'الخصم',
    share: 'مشاركة',
    noResult: 'لايوجد نتائج للبحث',
    confirmDelete: ' هل تريد الغاء الطلب ؟',
    loginQ: 'تسجيل الدخول ؟',
    cart: 'العربة',
    search: 'بحث',
    rimWidth: 'عرض الإطار',
    rimHeight: 'ارتفاع الإطار',
    rimSize: 'مقاس الجنط',
    brands: 'الماركات',
    discounts: 'الخصم',
    addToCart: 'اضف للعربة',
    purchase: 'شراء المنتج',
    seeMore: 'عرض المزيد',
    branch: 'فرع',
    getHelp: 'تحتاج للمساعدة ؟',
    email: 'ايميل',
    phones: 'تليفونات',
    cancelOrder: 'الغاء الطلب',
    date: 'تاريخ',
    orderId: 'رقم الطلب',
    waiting: 'انتظار',
    accepted: 'موافقة',
    charged: 'تم الشحن',
    delivered: 'تم التسليم',
    searchByOrderId: 'بحث برقم الطلب',
    previousOrders: 'الطلبات السابقة',
    status: 'الحالة',
    amount: 'القيمة',
    orderDate: 'تاريخ الطلب',
    ourBranches: 'فروعنا',
    contactUs: 'تواصل معنا',
    pleaseEnterPhoneAndPass: 'من فضلك أدخل رقم الهاتف و كلمة المرور',
    createNewAccount: 'انشاء حساب جديد',
    forgotYourPassword: 'هل نسيت كلمه المرور',
    or: 'أو',
    requiredField: 'حقل اجبارى',
    passwordMismatch: 'كلمة مرور غير مطابقة',
    enterValidName: 'من فضلك ادخل اسم صحيح',
    enterValidNumber: 'من فضلك ادخل رقم صحيح',
    area: 'المنطقة',
    phoneNumbers: 'أرقام التليفونات',
    shoppingCart: 'عربة التسوق',
    elements: 'عناصر',
    qty: 'الكمية',
    total: 'اجمالى',
    delete: 'مسح',
    egp: 'ج.م',
    continueShopping: 'تابع التسوق',
    summary: 'ملخص الطلب',
    totalValue: 'اجمالى القيمة',
    confirmOrder: 'تأكيد الطلب',
    paymentMethod: 'طريقة الدفع',
    cashOnDelivery: 'الدفع كاش عند الاستلام',
    visaOnDelivery: 'الدفع فيزا عند الاستلام',
    deliveryMethod: 'طريقة التوصيل',
    deliveryToHome: 'توصيل للمنزل',
    deliveryFrom3to7Days: 'التوصيل من 3 الى 7 ايام',
    mountInBranch: 'التركيب بالفرع',
    doYouReallyWantToConfirmYourOrder: 'هل ترغب بتأكيد الطلب؟',
    region: 'المنطقة',
    delivery: 'توصيل',
    positionOnMap: 'الموقع على الخريطة',
    changePassword: 'تغيير كلمة السر',
    confirmPhonenumber: 'تأكيد رقم التليفون',
    weWillSendOTPToYourPhone: 'سنقوم بإرسال رقم متغير الى تليفونك',
    enterAValidMobileNumber: 'من فضلك ادخل رقم تليفون صحيح',
    confirmOTP: 'تأكيد الرقم المتغير',
    pleaseEnterOTPSent: 'من فضلك ادخل الرقم المتغير',
    didntGetYourOTP: 'لم تستلم الرقم المتغير ؟',
    recentlyViewed: 'شوهد مؤخرا',
    viewMore: 'عرض المزيد',
    productDetails: 'تفاصيل المنتج',
    details: 'تفاصيل',
    productionDate: 'تاريخ الانتاج',
    buyProduct: 'شراء المنتج',
    relatedToYourSearch: 'اصناف مشابهة',
    trackYourOrder: 'تتبع طلبك',
    previousOrder: 'طلبات سابقة',
    enterOrderId: 'ادخل رقم الطلب',
    purchaseItem: 'شراء المنتج',
    addItemToCart: 'اضف للعربة',
    deliveryCost: 'تكلفة التوصيل',
    errorNotFound: 'خطا',
    notFound: 'صفحة غير موجودة',
    back: 'رجوع',
    backToHome: 'الرجوع الى الصفحة الرئيسية',
    allItemsAreSelected: ' الكل',
    services: 'الخدمات',
    workingHours: 'ساعات العمل',
    loginToCompleteOrder: 'تسجيل الدخول لاتمام الشراء',
    comingSoon: 'قريبا',
    terms: ' الشروط والاحكام',
    privacy: 'سياسية الخصوصية ',
    value: 'قيمة',
    acceptTerms: '  اوافق على ',
    and: 'و',
    selectArea: 'اختر المنطقة',
    contactUsText:'فى حالة وجود استفسار او شكوى يرجى الاتصال على الارقام التالية:   ',
    orEmailus:'أو ارسال رسالة على البريد الالكترونى: ',
    carservice:'خدمات السيارة',
    carService:'خدمات السيارة',
    Faq:'الأسئلة الشائعة ',
    faq:'الأسئلة الشائعة ',

    Q1:'هل الإطارات المعروضة تحت الضمان؟ ',
    A1:'جميع الإطارات المتاحة على موقعنا الإلكتروني جديدة تمامًا وذات قيمة كاملة وهي خاضعة لضمان الشركة المصنعة.',

    Q2:'ما هي طرق الدفع المقبولة؟',
    A2:'يمكنك الدفع نقدى او عبر بطاقات الائتمان',

    Q3:'هل تشمل الأسعار ضريبة القيمة المضافة؟',
    A3:'نعم ، تشمل الأسعار جميع الضرائب المطبقة. ستستلم فاتورة  ضريبة',

    Q4:'هل هناك اى رسوم إضافية لتركيب الاطارات؟',
    A4:'الاسعار المدرجة فى طلبك على موقعنا هى كل ما ستدفعه دون اى مصاريف خفية. يمكنك الوثوق من أن ما تراه هو ما ستدفعه . جميع العمليات المتعلقة بتركيب اطاراتك والتى تشمل  فك وتركيب الاطار , البلوف  , الترصيص ,تزويد الاطارات بالهواء او النيتروجين هى خدمات مجانية تقدم لك داخل الفرع',

    Q5:'ما هو تاريخ الانتاج للاطارات المعروضة على الموقع ؟',
    A5:'جميع الإطارات التي نقدمها جديدة ولا يزيد عمرها عن 24 شهرًا (وفقًا لتوصيات معظم خبراء ومصنيعى الاطارات) .نلتزم أيضًا بالتخزين المناسب للإطارات والذى يضمن التناوب المستمر للاطارات.على الرغم من أفضل جهودنا لضمان احدث تاريخ انتاج ، فإن الإطارات التي يشتريها العميل أكبر من 18 شهرًا ، فإننا نقدم للعميل خصمًا مقابل الإطارات التي يزيد عمرها عن 18 شهرًا',

    Q6:'كيف يمكنني تقديم شكوى؟',
    A6:'إذا لم تكن راضيًا عن مستوى الخدمة او وجود اى مشاكل لديك مرتبطة بالخدمة او المنتج  ، يجب عليك إبلاغ خدمة العملاء ، الذين سيطبقون إجراءات الشكاوى لدينا ويوجهون شكواك إلى الادارة المختصة',
   
    Q7:'لماذا الشراء من هنا ؟',
    A7_1:'1- التنوع: سوف تجد جميع الماركات العالميه التي تبحث عنها في مكان واحد',
    A7_2:'2- احصل على أفضل سعر بدون مصاريف خفية: نقدم لك افضل سعر للاطار دون اى مصاريف خفية . ستستلم فاتورة ضريبية  مئة بالمئة . لن تكون هناك اى مصاريف خفية . فالفك والتركيب والبلوف والترصيص مجانا',
    A7_3:'3- الدفع عبر بطاقات الائتمان: تستطيع الدفع من خلال البطاقات الائتمانية .',
    A7_4:'4- احفظ وقتك: نعلم أن وقتك ثمين وأن جدولك مزدحم ؛ لا طوابير فى موقعنا انت دائما رقم #1 قد يكون التسوق من أجل الحصول  على الإطارات مهمة شاقة وتستغرق وقتًا طويلاً. معنا الموضوع اصبح سهلا . سيسهل التسوق عبر موقعنا  عملية الشراء ولن تضطر حتى إلى مغادرة منزلك. جميع المعلومات التي تحتاجها متوفرة  (السعر , شكل النقشة , المواصفات ,) . وإذا لم تكن متأكدًا مقاس اطارك ، فيمكنك دائمًا  طلب المساعدة من خلال رقم الهاتف 15506 – 01220055500 – 01200600362 .',
    A7_5:'5- خدمة ما بعد البيع: من خلال مركز خدمة العملاء تستطيع طلب المشورة  فى اى وقت على مدار ايام الاسبوع  . واذا ما تطلب الامر فحص للاطار يتم توجيهك الى اقرب مركز خدمة اليك لاجراء الفحوصات الازمة على الاطار . فقط عليك الاتصال بـ 15506 – 01220055500 – 01200600362',
    A7_6:'6- الخدمة المتنقلة: تستطيع طلب تركيب الاطارات من خلال الخدمة المتنقلة . سياتى اليك فى المكان الذى تحدده سواء المنزل او مكان العمل فنين مهرة من خلال سيارة مجهزه خصيصا لتركيب الاطارات  هذه الخدمه متوفره فقط داخل القاهره',



  },
});
export default translation;
