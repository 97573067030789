import React, { useContext, useEffect, useState } from 'react';
import { SRLWrapper } from 'simple-react-lightbox';
import SimpleReactLightbox from 'simple-react-lightbox';
import '../ItemCard/ItemCard.scss';
import NumericInput from 'react-numeric-input';
import { ApplicationContext } from './../../../context/ApplicationContext';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import useQuantity from './../../../Custom-Hooks/useQuantityCtr';
import translation from './../../../localization/Language';

const ItemCard = (props) => {
  let { item } = props;
  const [appState, setAppState] = useContext(ApplicationContext);
  let [addItemToCart, onChange, itemIndex] = useQuantity(item);
  function gotoDetails() {
    const win = window.open('/Item?id=' + item.Id, '_blank');
    win.focus();
  }
  return (
    <div className="offer-slide">
      {item.DiscountPercentage > 0 && <div className="ribbon">{translation.discount} {item.DiscountPercentage}%</div>}
      <SimpleReactLightbox>
        <SRLWrapper>
          <LazyLoadImage effect="blur" src={'/appImages/' + item.Image} />
          <span className="d-none">
            <img src={'/appImages/1-' + item.Image} alt="" />
            <img src={'/appImages/2-' + item.Image} alt="" />
            <img src={'/appImages/3-' + item.Image} alt="" />
          </span>
        </SRLWrapper>
      </SimpleReactLightbox>
      <div className="slideInfo">
        <div>
          <h3 className="heading underline" onClick={gotoDetails}>
            {item.Name}
          </h3>
          <div className="info">
            {/* <span>{translation.size}:</span> */}
            {/* <span> {item.Id}</span> */}
          </div>
          <div className="price">
            <span className="new">{translation.egp}{item.NewPrice}</span>
            {item.DiscountPercentage > 0 && <span className="old">{translation.egp} {item.Price}</span>}
          </div>
        </div>
        <div className="d-grid gap-2">
          <div className="row">
            {itemIndex == -1 || !appState.cart[itemIndex] ? (
              <button
                type="button"
                className="btn col-12 col-sm-7 btn-primary add-cart-btn"
                onClick={addItemToCart}
              >
                <i className="fas fa-shopping-cart ms-2"></i> {translation.addItemToCart}
              </button>
            ) : (
              <div className="numericInputContainer col-12 col-sm-7">
                <NumericInput
                  className="form-control"
                  value={appState.cart[itemIndex].ItemsCount}
                  min={0}
                  mobile
                  onChange={onChange}
                />
              </div>
            )}
            <div className="col-12  col-sm-5 text-center align-self-center">
              <a href="#" className="underline" onClick={(e) => addItemToCart(e, true)}>
              {translation.purchaseItem}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ItemCard;
