import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { ApplicationContext } from '../context/ApplicationContext';
import CartService from '../services/cart.service';
import AuthService from '../services/auth.service';
const useQuantity = (item) => {
  const [appState, setAppState] = useContext(ApplicationContext);
  const [itemIndex, setItemIndex] = useState(-1);
  const history = useHistory();
  useEffect(() => {
    let index = appState.cart.findIndex((c) => c.Id == item.Id);
    setItemIndex(index);
  }, [item.Id]);
  async function addCart(addedItem) {
    let res = await new CartService().add(addedItem);
    let index = appState.cart.findIndex((c) => c.Id == item.Id);
    if (index !== -1) {
      appState.cart[index].CartId = res.Id;
      setAppState({
        ...appState,
        cart: [...appState.cart],
      });
    }
  }

  async function addItemToCart(e, isCheckOut) {
    let index = appState.cart.findIndex((c) => c.Id == item.Id);
    if (!isCheckOut || index == -1) {
      e.preventDefault();
      var len = appState.cart.push({ ...item, ItemsCount: 1, TotalPrice: item.NewPrice });
      setAppState({
        ...appState,
        cart: [...appState.cart],
      });
      setItemIndex(len - 1);
      await addCart({
        itemsCount: 1,
        totalPrice: item.NewPrice,
        itemId: item.Id,
        itemName: item.Name,
        image: item.Image,
        customerId: localStorage.anonymous || AuthService.getLoggedUser().Id,
        newPrice: item.NewPrice,
      });
    }
    if (isCheckOut) history.push('/check-out');
  }
  async function updateCart(item) {
    let res = await new CartService().edit(item);
  }
  async function updateCartData(e) {
    if (isNaN(e) || e == null) return;
    let index = appState.cart.findIndex((c) => c.Id == item.Id);
    if (index !== -1) {
      if (e > 0) {
        appState.cart[index].ItemsCount = e;
        appState.cart[index].TotalPrice = Math.round(e * appState.cart[index].NewPrice);
        setAppState({
          ...appState,
          cart: [...appState.cart],
          deliveryFees: appState.feesObj.Fees
            ? appState.feesObj.Fees + appState.feesObj.FeesPerUnit * (appState.itemsCount - 1)
            : 0,
        });
        updateCart({
          itemsCount: e,
          customerId: localStorage.anonymous || AuthService.getLoggedUser().Id,
          newPrice: appState.cart[index].TotalPrice,
          id: appState.cart[index].CartId,
        });
      } else {
        setAppState({ ...appState, cart: [...appState.cart.filter((c) => c.Id != item.Id)] });
        setItemIndex(-1);
        await new CartService().remove(appState.cart[index].CartId);
      }
    }
  }
  function onChange(e) {
    updateCartData(e);
  }
  return [addItemToCart, onChange, itemIndex, setItemIndex];
};
export default useQuantity;
