import React, { Fragment, useState, useEffect } from 'react';
import translation from '../../../localization/Language';
import { useForm } from 'react-hook-form';
import authService from '../../../services/auth.service';
import Loader from '../../shared/Loader/Loader';
import Logo from '../../../assets/imgs/logo2.png';
import FacebookButton from '../../shared/Facebook-Button/FacebookButton';
import ErrorAlert from '../../shared/ErrorAlert/ErrorAlert';
import AreaService from '../../../services/area.service';
import GoogleLocationPicker from '../../shared/Location-Picker/GoogleLocationPicker';
const Register = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    setValue,
  } = useForm({ mode: 'all' });
  const [submitState, setSubmitState] = useState(false);
  const [isFacebook, setIsFacebook] = useState(false);
  const [facebookId, setFacebookId] = useState('');
  const [errorState, setErrorState] = useState('');
  const [positionState, setPositionState] = useState({});
  const [termsState, setTermsState] = useState(false);
  const [areas, setAreas] = useState([]);
  useEffect(() => {
    async function getAreas() {
      let response = await new AreaService().get();
      setAreas(response);
    }
    getAreas();
  }, []);
  async function submit() {
    setSubmitState(true);
    try {
      await authService.register(
        {
          ...getValues(),
          facebookId: facebookId ? facebookId : '',
          lat: positionState.position?.lat,
          lng: positionState.position?.lng,
        },
        localStorage.anonymous || authService.getLoggedUser().Id
      );
      setSubmitState(false);
      window.location.reload();
    } catch (ex) {
      setSubmitState(false);
      setErrorState(ex);
    }
  }
  async function onFacebook(response) {
    setIsFacebook(true);
    setValue('name', response.name);
    setFacebookId(response.userID);
  }
  return (
    <div>
      <div className="text-center">
        <img alt="logo" height="50" src={Logo} />
      </div>

      <form onSubmit={handleSubmit(submit)} className="mx-6">
        <div className="input-group input-style-1">
          <span className="input-group-text" id="basic-addon1">
            <i className="far fa-id-badge"></i>
          </span>
          <input
            tabIndex="1"
            id="name"
            {...register('name', { required: true, pattern: /[A-Za-z ]+/ })}
            type="text"
            className={'form-control ' + (errors.name ? 'is-invalid' : '')}
            placeholder={translation.name}
          />
        </div>

        {errors.name && <p className="invalid-feedback">{translation.enterValidName}.</p>}

        <div className="input-group input-style-1">
          <span className="input-group-text" id="basic-addon1">
            <i className="fas fa-mobile-alt"></i>
          </span>
          <input
            tabIndex="2"
            id="mobileNumber"
            {...register('mobileNumber', { required: true, pattern: /^01[0-9]{9}$/ })}
            type="text"
            className={'form-control ' + (errors.mobileNumber ? 'is-invalid' : '')}
            placeholder={translation.mobileNumber}
          />
        </div>

        {errors.mobileNumber && <p className="invalid-feedback">{translation.enterValidNumber}.</p>}

        <div className="input-group input-style-1">
          <span className="input-group-text" id="basic-addon1">
            <i className="fas fa-map-marker-alt"></i>
          </span>
          <input
            tabIndex="3"
            id="address"
            {...register('address', { required: true })}
            type="text"
            className={'form-control ' + (errors.address ? 'is-invalid' : '')}
            placeholder={translation.address}
          />
        </div>

        {errors.address && <p className="invalid-feedback">{translation.requiredField}.</p>}

        <div className="input-group input-style-1">
          <span className="input-group-text" id="basic-addon1">
            <i className="fas fa-search-location"></i>
          </span>
          <select
            tabIndex="4"
            id="areaId"
            {...register('areaId', { required: true })}
            type="text"
            className={'form-control ' + (errors.area ? 'is-invalid' : '')}
            placeholder={translation.area}
          >
            <option value="">{translation.selectArea}</option>
            {areas.map((area) => (
              <option value={area.Id}>{area.Name}</option>
            ))}
          </select>
        </div>

        {errors.area && <p className="invalid-feedback">{translation.requiredField}.</p>}

        <div className="input-group input-style-1">
          <span className="input-group-text" id="basic-addon1">
            <i className="fas fa-map-marker-alt"></i>
          </span>
          {/* <input
            tabIndex="5"
            id="location"
            {...register('location', { required: false })}
            type="text"
            placeholder={translation.location}
          /> */}
        </div>
        <GoogleLocationPicker
          positionState={positionState}
          setPosition={setPositionState}
        ></GoogleLocationPicker>
        {!isFacebook && (
          <Fragment>
            <div className="input-group input-style-1">
              <span className="input-group-text" id="basic-addon1">
                <i className="fas fa-lock"></i>
              </span>
              <input
                tabIndex="6"
                id="password"
                {...register('password', { required: !isFacebook })}
                type="password"
                className={'form-control ' + (errors.password ? 'is-invalid' : '')}
                placeholder={translation.password}
              />
            </div>

            {errors.password && <p className="invalid-feedback">{translation.requiredField}.</p>}

            <div className="input-group  input-style-1">
              <span className="input-group-text" id="basic-addon1">
                <i className="fas fa-lock"></i>
              </span>
              <input
                tabIndex="7"
                id="confirmPassword"
                {...register('confirmPassword', {
                  validate: (value) => value === getValues().password,
                })}
                type="password"
                className={'form-control ' + (errors.confirmPassword ? 'is-invalid' : '')}
                placeholder={translation.confirmPassword}
              />
            </div>
            {errors.confirmPassword && (
              <p className="invalid-feedback">{translation.passwordMismatch}.</p>
            )}
          </Fragment>
        )}
        <div className="mt-4">
          <input
            type="checkbox"
            id="terms"
            name="tersm"
            value={termsState}
            onChange={(e) => {
              setTermsState(!termsState);
            }}
          />
          <label for="terms">
            {translation.acceptTerms}
            <a href="/terms&conditions" target="_blank">
              {translation.terms}&ensp;
            </a>
            {translation.and}&ensp;
            <a href="/privacy&policy" target="_blank">
              {translation.privacy}
            </a>
          </label>
        </div>
        <div className="d-grid gap-2">
          <button
            tabIndex="8"
            disabled={!isValid || submitState || !termsState}
            className={'btn btn-primary mt-3 '}
            type="submit"
          >
            {!submitState && translation.register}
            {submitState && <Loader display="true" position="relative" color="white"></Loader>}
          </button>
          {!isFacebook && (
            <Fragment>
              <div className="text-center my-2">او</div>
              <div className="d-grid gap-2">
                <FacebookButton
                  disable={!termsState}
                  tabIndex="9"
                  onFacebook={onFacebook}
                ></FacebookButton>
              </div>
            </Fragment>
          )}
        </div>
        {errorState && <ErrorAlert message={errorState}></ErrorAlert>}
      </form>
      {/* <FormsModal show={true}>
        <ChooseLocation></ChooseLocation>
      </FormsModal> */}
    </div>
  );
};
export default Register;
