import React, { useEffect, useState, useContext, Fragment } from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './ItemDetails.scss'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import {
  FacebookShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton,
  TelegramShareButton,
  FacebookIcon,
  WhatsappIcon,
  TelegramIcon,
  FacebookMessengerIcon,
} from 'react-share';
import { useLocation, useHistory } from 'react-router';
import itemService from '../../services/item.service';
import ItemShimmer from './../shared/Item-Shimmer/ItemShimmer';
import { ApplicationContext } from '../../context/ApplicationContext';
import authService from '../../services/auth.service';
import cartService from '../../services/cart.service';
import NumericInput from 'react-numeric-input';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import translation from './../../localization/Language';
import Moment from 'react-moment';
import ItemCard from './../shared/ItemCard/ItemCard';
import '../home/Home.scss';
import Footer from './../common/Footer/Footer';
import useQuantity from './../../Custom-Hooks/useQuantityCtr';

const ItemDetails = (props) => {
  const shareUrl = window.location.href;
  const [itemState, setItemState] = useState({});
  const [relatedItemState, setRelatedItemState] = useState([]);
  const [appState, setAppState] = useContext(ApplicationContext);
  let [addItemToCart, onChange, itemIndex, setItemIndex] = useQuantity(itemState);
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  useEffect(() => {
    async function getItem() {
      var data = await new itemService().getByCode(
        query.get('id'),
        localStorage.anonymous || authService.getLoggedUser().Id
      );
      setItemState(data.Product);
      setRelatedItemState(data.ProductList);
    }
    getItem();
  }, []);

  useEffect(() => {
    let index = appState.cart.findIndex((c) => c.Id == itemState.Id);
    setItemIndex(index);
  }, [itemState]);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {translation.share}
    </Tooltip>
  );
  const imageExist = (e) => {
    e.target.onerror = null;
    e.target.parentElement.remove();
  };
  return (
    <div className="container checkout-page">
      <div className="row">
        <Breadcrumb className="mt-3">
          <Breadcrumb.Item href="/">{translation.home}</Breadcrumb.Item>
          <Breadcrumb.Item href="#">{translation.productDetails}</Breadcrumb.Item>
          <Breadcrumb.Item active>{itemState.Id}</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="row main-container mb-4">
        <div className="col-lg-5 order-1 order-lg-0">
          <h2 className="heading-1 f-bold">{itemState.Name} </h2>
          <hr className="hr1" />
          <div className="flex-3 mb-3">
            <div className="price-status">
              <h3 className="heading-3 f-bold text-success mb-0">
                {itemState.NewPrice} {translation.egp}
              </h3>
              <div className="price">
                <span className="old-price">
                  {translation.egp}
                  {itemState.Price}
                </span>
              </div>
            </div>
            <div className="social">
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <FacebookShareButton url={shareUrl}>
                  {/* <button type="button" class="btn facebook me-3">
                  <i class="fab fa-facebook-f ms-2"></i>
                </button> */}

                  <FacebookIcon size={35} round={true} />
                </FacebookShareButton>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <WhatsappShareButton url={shareUrl}>
                  {/* <button type="button" class="btn whatsapp">
                  <i class="fab fa-whatsapp ms-2"></i>{' '}
                </button> */}
                  <WhatsappIcon size={35} round={true} />
                </WhatsappShareButton>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <TelegramShareButton url={shareUrl}>
                  {/* <button type="button" class="btn whatsapp">
                  <i class="fab fa-whatsapp ms-2"></i>{' '}
                </button> */}
                  <TelegramIcon size={35} round={true} />
                </TelegramShareButton>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <FacebookMessengerShareButton url={shareUrl}>
                  {/* <button type="button" class="btn whatsapp">
                  <i class="fab fa-whatsapp ms-2"></i>{' '}
                </button> */}
                  <FacebookMessengerIcon size={35} round={true} />
                </FacebookMessengerShareButton>
              </OverlayTrigger>
            </div>
          </div>
          <div className="item_details-info">
            <p>
              {translation.discount} : {itemState.DiscountPercentage}%
            </p>
            <p>
              {translation.size} : {itemState.Id}
            </p>
            <p>
              {translation.details}:{itemState.Info}
            </p>
            {/* <p>
              {translation.productionDate} :{' '}
              <Moment format="YYYY/MM/DD">{itemState.ExpireDate}</Moment>{' '}
            </p> */}
          </div>

          <div className="mt-4 btns">
            {itemIndex == -1 || !appState.cart[itemIndex] ? (
              <Fragment>
                <button
                  type="button"
                  className="btn btn-primary col-6 mt-4"
                  onClick={addItemToCart}
                >
                  <i className="fas fa-shopping-cart ms-2"></i> {translation.addToCart}
                </button>
              </Fragment>
            ) : (
              <div className="offer-slide col-6 mt-4">
                <div className="numericInputContainer  ">
                  <NumericInput
                    className="form-control"
                    value={appState.cart[itemIndex].ItemsCount}
                    min={0}
                    mobile
                    onChange={onChange}
                  />
                </div>
              </div>
            )}
            <button
              type="button"
              className="btn btn-primary col-5 mt-4 offset-1"
              onClick={(e) => addItemToCart(e, true)}
            >
              {translation.buyProduct}
            </button>
          </div>
        </div>

        <div className="col-lg-7 order-0 order-lg-1">
          <Carousel
            className="fade-in"
            showIndicators={false}
            showArrows={false}
            showStatus={false}
            useKeyboardArrows={false}
            autoPlay={false}
          >
            <div>
              <img
                src={'/appImages/' + itemState.Image}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = '/appImages/Not-Found.png';
                }}
              />
            </div>
            <div>
              <img src={'/appImages/1-' + itemState.Image} onError={imageExist} />
            </div>
            <div>
              <img src={'/appImages/2-' + itemState.Image} onError={imageExist} />
            </div>
            <div>
              <img src={'/appImages/3-' + itemState.Image} onError={imageExist} />
            </div>
          </Carousel>
        </div>
        <div className="mt-4 col-12  order-lg-3 order-2 ">
          {/* {[1, 2, 3, 4, 5].map((c) => (
            <ItemCard item={c} key={c.Id}></ItemCard>
          ))} */}
          <div className="px-md-5 px-1">
            <h2 className="text-center my-3 f-bold crossline-header">
              <span>{translation.relatedToYourSearch}</span>
            </h2>
          </div>
          <div className="row search-results slick-track relatedItmes">
            {relatedItemState.map((item) => (
              <Fragment>
                <div className="col-lg-3 col-sm-6  mb-4">
                  <div className="slick-slide fade-in">
                    <ItemCard item={item}></ItemCard>
                  </div>
                </div>
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ItemDetails;
