import React from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import authService from '../../../services/auth.service';
const FacebookButton = (props) => {
  const { onFacebook, disable } = props;
  const responseFacebook = async (response) => {
    onFacebook(response);
  };
  return (
    <FacebookLogin
      appId="175508964647709"
      callback={responseFacebook}
      render={(renderProps) => (
        <button
          onClick={renderProps.onClick}
          type="button"
          tabIndex={props.tabIndex}
          className={'btn  facebook  text-light'}
          disabled={disable}
        >
          Facebook <i class="fab fa-facebook-f"></i>
        </button>
      )}
    />
  );
};
export default FacebookButton;
