import React, { useState, useContext, useEffect } from 'react';
import styles from './Login.scss';
import translation from '../../../localization/Language';
import { useForm } from 'react-hook-form';
import authService from '../../../services/auth.service';
import Loader from '../../shared/Loader/Loader';
import Logo from '../../../assets/imgs/logo2.png';
import ErrorAlert from '../../shared/ErrorAlert/ErrorAlert';
import FacebookButton from '../../shared/Facebook-Button/FacebookButton';
import { ApplicationContext } from '../../../context/ApplicationContext';

const Login = (props) => {
  let { setPagesState, pagesState } = props;
  let [errorState, setErrorState] = useState('');
  const [appState, setAppState] = useContext(ApplicationContext);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
  } = useForm({ mode: 'all' });
  const [submitState, setSubmitState] = useState(false);

  async function submit(e, facebookId) {
    setSubmitState(true);
    try {
      await authService.login({
        ...getValues(),
        customerId: localStorage.anonymous || authService.getLoggedUser().Id,
        facebookId: facebookId ? facebookId : '',
      });
      window.location.reload();
    } catch (ex) {
      setErrorState(ex);
      setSubmitState(false);
    }
  }
  async function onFacebook(response) {
    submit(null, response.id);
  }
  return (
    <div className="fade-in">
      <div className="text-center">
        <img alt="logo" height="50" src={Logo} />
      </div>
      <hr className="dashed" />
      <p className="text-center">{translation.pleaseEnterPhoneAndPass}</p>
      <form className={styles.inner} onSubmit={handleSubmit((e) => submit(e, ''))}>
        <div className="input-group mb-3 input-style-1">
          <span className="input-group-text" id="basic-addon1">
            <i className="fas fa-mobile-alt"></i>
          </span>
          <input
            tabIndex="1"
            id="mobileNumber"
            {...register('mobileNumber', { required: true, pattern: /^01[0-9]{9}$/ })}
            type="text"
            className={'form-control ' + (errors.mobileNumber ? 'is-invalid' : '')}
            placeholder={translation.mobileNumber}
          />
        </div>
        {errors.mobileNumber && <p className="invalid-feedback">{translation.requiredField}.</p>}
        <div className="input-group mb-3 input-style-1">
          <span className="input-group-text" id="basic-addon1">
            <i className="fas fa-lock"></i>
          </span>
          <input
            tabIndex="2"
            id="password"
            {...register('password', { required: true })}
            type="password"
            className={'form-control' + (errors.password ? 'is-invalid' : '')}
            placeholder={translation.password}
          />
        </div>

        {errors.password && <p className="invalid-feedback">{translation.requiredField}.</p>}

        <div className={'row ' + styles.btnRow}>
          <div className="col-12">
            <div className="d-grid gap-2">
              <button
                tabIndex="3"
                disabled={!isValid || submitState}
                className={'btn btn-primary mt-3 ' + styles.loginBtn}
                type="submit"
              >
                {!submitState && translation.login}
                {submitState && <Loader display="true" position="relative" color="white"></Loader>}
              </button>
            </div>
            <div className="info">
              <span
                className="pretty-text-underline pointer"
                onClick={() => setPagesState({ ...pagesState, login: false, reset: true })}
              >
                {translation.forgotYourPassword}
              </span>
              <span
                className="pretty-text-underline pointer"
                onClick={() => setPagesState({ ...pagesState, login: false, register: true })}
              >
                {translation.createNewAccount}
              </span>
            </div>
            {/* <div className="text-center">{translation.or}</div> */}
            <div className="d-grid gap-2">
              <FacebookButton tabIndex="4" onFacebook={onFacebook}></FacebookButton>
            </div>
          </div>
        </div>
        {errorState && <ErrorAlert message={errorState}></ErrorAlert>}
      </form>
    </div>
  );
};
export default Login;
